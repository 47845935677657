a<template>
<svg
    xmlns="http://www.w3.org/2000/svg"
    width="129.5317"
    height="30.8252"
    viewBox="0 0 129.5317 30.8252"
    class="ericfonden-logo"
    :class="{ 'ericfonden-logo--inverted' : inverted}"
>
  <g>
    <path d="M6.0626,31.4354v.6582H3.107V27.2674H6v.6582H3.8213V29.39h1.898v.666H3.8213v1.38Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M9.1138,30.2938H8.56v1.8H7.8389V27.2674H9.6807a1.4105,1.4105,0,0,1,1.4849,1.5274,1.3774,1.3774,0,0,1-1.24,1.4844l1.2818,1.8144h-.8614ZM8.56,29.6629h.9458c.6651,0,.9385-.3574.9385-.8681a.7834.7834,0,0,0-.7705-.8692H8.56Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M12.9629,27.2674h.7212v4.8262h-.7212Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M15.4336,29.6913a2.454,2.454,0,0,1,2.4512-2.4727,2.3568,2.3568,0,0,1,1.8565.8682l-.5327.4277a1.6736,1.6736,0,0,0-1.3165-.623,1.8009,1.8009,0,0,0,0,3.6,1.6976,1.6976,0,0,0,1.3165-.6231l.5327.4268a2.357,2.357,0,0,1-1.8565.8681A2.4537,2.4537,0,0,1,15.4336,29.6913Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M26.7339,27.9256H25.2984v4.168h-.7217v-4.168H23.1412v-.6582h3.5927Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M27.754,29.6913a2.4445,2.4445,0,1,1,2.4375,2.4716A2.4394,2.4394,0,0,1,27.754,29.6913Zm4.1674,0a1.7245,1.7245,0,1,0-1.73,1.8A1.7517,1.7517,0,0,0,31.9214,29.6913Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M35.6651,30.2938h-.5537v1.8H34.39V27.2674H36.232a1.4105,1.4105,0,0,1,1.4849,1.5274,1.3774,1.3774,0,0,1-1.24,1.4844l1.2817,1.8144h-.8613Zm-.5537-.6309h.9458c.665,0,.9385-.3574.9385-.8681a.7834.7834,0,0,0-.77-.8692H35.1114Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M42.47,31.4354v.6582H39.5142V27.2674h2.8926v.6582H40.2286V29.39h1.8979v.666H40.2286v1.38Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M44.2461,27.2674h.7212v4.168h1.9405v.6582H44.2461Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M48.4742,27.2674h.7212v4.168h1.94v.6582H48.4742Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M52.3169,30.6922h.7144a.9235.9235,0,0,0,1.0015.8125.8074.8074,0,0,0,.9106-.749c0-.4277-.3852-.6308-.7773-.7353l-.6162-.1543c-1.03-.2588-1.1978-.8682-1.1978-1.2676a1.4728,1.4728,0,0,1,1.604-1.3867,1.4257,1.4257,0,0,1,1.5479,1.4218H54.79a.8681.8681,0,0,0-1.7163-.0488c0,.1533.0493.4756.6587.63l.6094.1543c.812.2031,1.3232.6445,1.3232,1.3516a1.4965,1.4965,0,0,1-1.6318,1.4423A1.5794,1.5794,0,0,1,52.3169,30.6922Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M64.709,32.0936l-.4272-3.5019-1.4356,2.97H62.23l-1.45-2.97-.4272,3.5019h-.7144l.5879-4.8262h.6445l1.66,3.4746,1.66-3.4746h.6445l.5879,4.8262Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M67.22,27.2674h.7207v4.8262H67.22Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M73.5215,27.2674v4.8262h-.68L70.7335,28.577v3.5166h-.7207V27.2674h.7L72.8,30.7557V27.2674Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M79.1006,27.2674v4.8262h-.68L76.3126,28.577v3.5166h-.7207V27.2674h.7l2.087,3.4883V27.2674Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M84.126,31.4354v.6582H81.171V27.2674h2.8925v.6582H81.8848V29.39h1.8985v.666H81.8848v1.38Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M85.6016,30.6922h.7139a.9239.9239,0,0,0,1.0019.8125.8072.8072,0,0,0,.91-.749c0-.4277-.3848-.6308-.7773-.7353l-.6163-.1543c-1.0293-.2588-1.1972-.8682-1.1972-1.2676A1.4723,1.4723,0,0,1,87.24,27.2118a1.4256,1.4256,0,0,1,1.5478,1.4218h-.7138a.8684.8684,0,0,0-1.7168-.0488c0,.1533.05.4756.6592.63l.6093.1543c.8116.2031,1.3233.6445,1.3233,1.3516a1.4966,1.4966,0,0,1-1.6319,1.4423A1.5793,1.5793,0,0,1,85.6016,30.6922Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M91.4053,27.9256V29.39h1.6875v.6592H91.4053v2.0449h-.7217V27.2674h2.8506v.6582Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M94.7579,29.6913a2.4445,2.4445,0,1,1,2.4375,2.4716A2.4394,2.4394,0,0,1,94.7579,29.6913Zm4.167,0a1.7244,1.7244,0,1,0-1.7295,1.8A1.7514,1.7514,0,0,0,98.9249,29.6913Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M104.9024,27.2674v4.8262h-.68l-2.1074-3.5166v3.5166h-.7217V27.2674h.7l2.0869,3.4883V27.2674Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M106.9727,27.2674h1.877a2.19,2.19,0,0,1,2.1855,2.4239,2.1761,2.1761,0,0,1-2.1855,2.4023h-1.877Zm1.877,4.168c.9668,0,1.4638-.7637,1.4638-1.7441s-.497-1.7657-1.4638-1.7657h-1.1553v3.51Z" transform="translate(-1.3912 -1.3378)"/>
  </g>
  <g>
    <path d="M8.3028,4.8573c-.5762,0-.8.2563-.8.9922v5.6H9.0064a2.6156,2.6156,0,0,0,2.8481-1.9839c.064-.3521.2559-.544.4478-.5122.2241,0,.3843.1919.3843.48.0317.5122-.0645,1.5361-.0645,2.56v.128c0,1.0239.0962,2.0478.0645,2.56,0,.2881-.16.48-.3843.48-.1919,0-.3838-.16-.4478-.5117a2.6111,2.6111,0,0,0-2.8481-2.0161H7.5025v5.6318c0,.7354.2241.96.8.96h2.8154c1.9839,0,3.7442-3.104,4.3521-4.7359.16-.416.3838-.5757.64-.5757a.5686.5686,0,0,1,.416.6719c-.0317.64-.2236,2.1118-.5117,4.48-.1279,1.1836-.0962,1.5684-.6079,1.5684a16.6313,16.6313,0,0,0-2.1118-.1924H5.0069c-1.6,0-2.7837.0635-3.2.0635-.2559,0-.416-.127-.416-.2871,0-.1924.1279-.32.4477-.4161.4483-.0957.6084-.2558.6084-.7041V5.0174c0-.4482-.16-.6079-.6084-.7358-.32-.064-.4477-.2241-.4477-.3843s.16-.2876.416-.2876c.416,0,1.6.064,3.2.064,1.248,0,7.2954-.0322,7.7114-.0322A16.662,16.662,0,0,0,14.83,3.45c.5122,0,.48.3838.6079,1.5678.2881,2.3677.4161,3.4878.4483,4.16.0317.3521-.16.64-.416.64-.2564.064-.5122-.0961-.6719-.5439-.5762-1.6318-2.3042-4.416-4.2559-4.416Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M23.5982,19.8319c.2881.0644.416.2246.416.3848s-.16.2871-.416.2871c-.416,0-1.5039-.0635-3.0718-.0635s-2.6557.0635-3.1035.0635c-.2241,0-.3843-.127-.3843-.2871s.128-.32.416-.3848c.4483-.16.64-.2871.64-.7354V10.329a.8213.8213,0,0,0-.64-.7359c-.288-.0639-.416-.2241-.416-.3838s.16-.2881.3843-.32a13.438,13.438,0,0,0,2.88-.768,10.961,10.961,0,0,1,2.3037-.7676c.3521,0,.3521.48.3521,2.4638v.8c1.5039-2.1118,2.6557-3.5839,4.3515-3.5839a2.6364,2.6364,0,0,1,2.5278,2.8481,2.4,2.4,0,0,1-2.144,2.56c-.352,0-.6079-.2241-1.3437-1.12-.5123-.6723-.7681-.8959-1.28-.8959a4.205,4.205,0,0,0-2.1118,1.4077v7.2636C22.9586,19.5448,23.1182,19.6717,23.5982,19.8319Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M35.8863,19.0965c0,.4483.1919.5752.64.7354.2881.0644.416.2246.416.3848s-.16.2871-.3837.2871c-.4161,0-1.5362-.0635-3.104-.0635s-2.6558.0635-3.0718.0635c-.2559,0-.416-.127-.416-.2871s.1279-.32.416-.3848c.48-.16.64-.2871.64-.7354V10.329a.8212.8212,0,0,0-.64-.7359c-.2881-.0639-.416-.2241-.416-.3838s.16-.2881.416-.32a16.586,16.586,0,0,0,3.04-.768c1.3438-.4478,1.6636-.7676,2.1118-.7676.3521,0,.3521.32.3521.7358Zm-2.56-12.3833a2.8639,2.8639,0,0,1-3.0078-2.6879,3.045,3.045,0,0,1,6.0479,0A2.8737,2.8737,0,0,1,33.3262,6.7132Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M49.8375,17.9447a6.3692,6.3692,0,0,1-5.5039,2.7837,6.47,6.47,0,0,1-6.5914-6.72A6.6944,6.6944,0,0,1,44.75,7.0331c2.4961,0,4.5439,1.4082,5.0556,3.2319a2.4471,2.4471,0,0,1-2.2715,3.1357c-1.1523,0-1.9838-.832-1.9838-2.4316,0-1.6641-.32-2.8481-1.2163-2.8481-.8638,0-1.7276,1.7919-1.7276,4.0317,0,3.7119,2.3037,5.9839,4.2236,5.9839a4.46,4.46,0,0,0,2.5918-.7359C49.6456,17.2723,50.0294,17.5287,49.8375,17.9447Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M56.6216,8.4413V19.0965c0,.4483.1919.5752.64.7354.32.0644.4482.2246.4482.3848s-.16.2871-.416.2871c-.4482,0-1.5039-.0635-3.0718-.0635-1.6,0-2.688.0635-3.104.0635-.2558,0-.416-.127-.416-.2871s.1279-.32.4483-.3848c.4477-.16.6079-.2871.6079-.7354V8.4413h-.5762c-.32,0-.416-.128-.416-.32V7.6732c0-.1919.0962-.32.3843-.32h.64V7.3211c0-3.9038,1.9839-5.9194,4.64-5.9194,1.8559,0,3.6157.9917,4.0317,2.56a1.7816,1.7816,0,0,1-1.7275,2.4639c-1.1519,0-1.92-.6719-1.92-2.2076,0-.96-.2241-1.728-.896-1.728-.7041,0-1.12.6719-1.12,1.8237a3.64,3.64,0,0,0,1.8242,3.04h1.3438c.32,0,.416.1279.416.32V8.121c0,.1923-.1279.32-.4482.32Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M72.876,13.8807a7.0255,7.0255,0,1,1-7.039-6.8476A6.8921,6.8921,0,0,1,72.876,13.8807Zm-5.3437-.5117c-.7031-4.0317-1.7593-5.28-2.6553-5.12s-1.4717,1.6958-.7676,5.7275c.7359,4.0635,1.8877,5.6636,2.72,5.5034C67.6924,19.3524,68.2374,17.4647,67.5323,13.369Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M79.2774,11.1288v7.9677c0,.4483.1924.5752.64.7354.2881.0644.4161.2246.4161.3848s-.16.2871-.3838.2871c-.4483,0-1.5362-.0635-3.1045-.0635s-2.6553.0635-3.0713.0635c-.2559,0-.416-.127-.416-.2871s.1279-.32.416-.3848c.4482-.16.64-.2871.64-.7354V10.329a.8207.8207,0,0,0-.64-.7359c-.2881-.0639-.416-.2241-.416-.3838s.16-.2881.416-.32a12.8039,12.8039,0,0,0,2.8476-.768c1.376-.48,1.2481-.7676,1.6963-.7676.3516,0,.4473.416.96,2.3037a4.2123,4.2123,0,0,1,3.8076-2.624,3.7938,3.7938,0,0,1,4.0313,3.84v8.2236c0,.4483.1923.5752.6406.7354.2871.0644.416.2246.416.3848s-.16.2871-.3848.2871c-.4472,0-1.5351-.0635-3.1035-.0635s-2.6562.0635-3.0722.0635c-.2559,0-.4161-.127-.4161-.2871s.129-.32.4161-.3848c.4492-.16.6406-.2871.6406-.7354V11.1288a1.4885,1.4885,0,1,0-2.9756,0Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M96.1729,5.7855c-.2881-.0962-.3838-.2241-.3838-.416,0-.16.1279-.2881.3838-.32a12.4543,12.4543,0,0,0,3.0723-.8c1.3437-.5757,1.7275-1.0557,2.0791-1.0557s.3525.32.3525.7358v15.167c0,.4483.1914.544.64.6719.32.0957.416.2559.416.416s-.128.2881-.3838.2881c-.416,0-1.1524-.0644-3.1035-.0322-1.7285.0322-2.4326.3193-2.4326-.3193V18.7762a3.7736,3.7736,0,0,1-3.1036,1.9522c-2.6552,0-5.0234-2.5279-5.0234-6.9434s2.3682-6.7519,5.0234-6.7519a3.6519,3.6519,0,0,1,3.1036,2.208V6.4891A.7528.7528,0,0,0,96.1729,5.7855Zm-2.624,7.9351c0,3.2959.8642,4.7041,1.76,4.7041.8008,0,1.4405-1.2481,1.504-4.2242v-.48c0-3.2637-.6075-4.4156-1.504-4.4156C94.3809,9.305,93.5489,10.4569,93.5489,13.7206Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M115.628,13.1127c0,.1923-.1279.32-.416.32h-7.1992c.4482,2.9438,2.4316,4.7036,4.1279,4.7036a4.7986,4.7986,0,0,0,2.6875-.7359c.2246-.1284.6084.128.416.544a7.09,7.09,0,0,1-5.6,2.7837,6.47,6.47,0,0,1-6.5918-6.72,6.6947,6.6947,0,0,1,7.0078-6.9755C113.58,7.0331,115.628,9.433,115.628,13.1127Zm-7.711-.7676h2.2715c.2559,0,.6719-.0323.6719-1.376,0-1.6641-.3516-2.8481-1.2158-2.8481s-1.7276,1.7919-1.7276,4.0317Z" transform="translate(-1.3912 -1.3378)"/>
    <path d="M122.0274,11.1288v7.9677c0,.4483.1924.5752.6406.7354.2881.0644.416.2246.416.3848s-.16.2871-.3847.2871c-.4473,0-1.5352-.0635-3.1035-.0635s-2.6553.0635-3.0713.0635c-.2569,0-.416-.127-.416-.2871s.1279-.32.416-.3848c.4472-.16.64-.2871.64-.7354V10.329a.8219.8219,0,0,0-.64-.7359c-.2881-.0639-.416-.2241-.416-.3838s.1591-.2881.416-.32a12.8039,12.8039,0,0,0,2.8476-.768c1.376-.48,1.2481-.7676,1.6953-.7676.3526,0,.4483.416.96,2.3037a4.2123,4.2123,0,0,1,3.8076-2.624,3.7946,3.7946,0,0,1,4.0322,3.84v8.2236c0,.4483.1914.5752.64.7354.2881.0644.416.2246.416.3848s-.16.2871-.3838.2871c-.4482,0-1.5361-.0635-3.1035-.0635s-2.6562.0635-3.0723.0635c-.2558,0-.416-.127-.416-.2871s.128-.32.416-.3848c.4483-.16.64-.2871.64-.7354V11.1288a1.4885,1.4885,0,1,0-2.9756,0Z" transform="translate(-1.3912 -1.3378)"/>
  </g>
</svg>
</template>
<script>
export default {
  props: {
      inverted: Boolean
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="scss">
svg.ericfonden-logo {
    display: block;
    max-width: 100%;
    fill: var(--accent-color-1);

    &--inverted {
        fill: #ffffff;
    }
}
</style>
